import React from 'react'
import classnames from 'classnames'
import Label from './label'
import { Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import IconRightArrow from '../../images/arrow-right-alt.inline.svg'

function CardTopImage({
  className,
  variant,
  label,
  title,
  subtitle,
  description,
  link,
  image,
}) {
  return (
    <div
      className={classnames(
        className,
        variant === 'light' ? 'bg-lw-white' : 'bg-lw-blue-dark',
        variant === 'light' ? 'text-lw-black' : 'text-lw-white',
        'rounded-2xl',
        'overflow-hidden'
      )}
    >
      {image && (
        <GatsbyImage
          className={classnames('w-full')}
          image={image?.childImageSharp?.gatsbyImageData}
          alt={title}
        />
      )}

      <div
        className={classnames(
          'flex-none',
          'flex',
          'flex-col',
          'items-start',
          'p-8',
          'pb-12'
        )}
      >
        {label && (
          <Label
            text={label}
            variant={variant === 'light' ? 'dark' : 'light'}
          />
        )}

        {title && (
          <div
            className={classnames(
              'font-light',
              'text-[32px]',
              'tracking-[-1.2px]',
              'leading-[120%]',
              'mt-auto',
              'lg:text-[40px]'
            )}
          >
            {title}
          </div>
        )}

        {subtitle && (
          <div
            className={classnames(
              'text-[#959595]',
              'font-light',
              'text-[32px]',
              'tracking-[-1.2px]',
              'leading-[120%]',
              'lg:text-[40px]'
            )}
          >
            {subtitle}
          </div>
        )}

        {description && (
          <div
            className={classnames(
              'mt-3',
              'text-[#959595]',
              'leading-[160%]',
              'tracking-[-0.16px]'
            )}
          >
            {description}
          </div>
        )}

        {link && (
          <div className="mt-7">
            <Link
              className={classnames(
                variant === 'light' ? 'text-lw-black' : 'text-lw-white',
                variant === 'light'
                  ? 'hover:text-lw-black'
                  : 'hover:text-lw-white',
                variant === 'light' ? 'border-lw-black' : 'border-lw-white',
                'text-xl',
                'border-b',
                'border-solid',
                'py-1',
                'tracking-[-0.6px]'
              )}
              to={link?.url}
            >
              {link?.text}
              <IconRightArrow className="inline-block ml-2" />
            </Link>
          </div>
        )}
      </div>
    </div>
  )
}

export default CardTopImage

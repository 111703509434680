import React from 'react'
import classnames from 'classnames'

function Chip({ label }) {
  return (
    <span
      className={classnames(
        'px-4',
        'py-3',
        'rounded-full',
        'text-lw-black',
        'bg-lw-white',
        'tracking-[0.32px]'
      )}
    >
      {label}
    </span>
  )
}

export default Chip

import React from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import classnames from 'classnames'

// Components
import Layout from '../../../components/layout'
import Seo from '../../../components/Seo'
import Hero from '../../../components/cloud-studio/hero'
import Card from '../../../components/cloud-studio/card'
import CardIcon from '../../../components/cloud-studio/card-icon'
import CardFullWidthImage from '../../../components/cloud-studio/card-full-width-image'
import CardTopImage from '../../../components/cloud-studio/card-top-image'
import SectionTitle from '../../../components/cloud-studio/section-title'
import Accordion from '../../../components/cloud-studio/accordion'
import IconList from '../../../components/cloud-studio/icon-list'
import IconListItem from '../../../components/cloud-studio/icon-list-item'
import Table from '../../../components/cloud-studio/table'
import Button from '../../../components/cloud-studio/button'
import { Tabs, TabContent } from '../../../components/cloud-studio/tabs'
import Chip from '../../../components/cloud-studio/chip'

// Assets
import HollowCircle from '../../../images/cloud-studio/hollow-circle.inline.svg'
import Intersection from '../../../images/cloud-studio/intersection.inline.svg'
import TimeGraph from '../../../images/cloud-studio/time-graph.inline.svg'
import Grow from '../../../images/cloud-studio/grow.inline.svg'
import Hardware from '../../../images/cloud-studio/hardware.inline.svg'
import Distro from '../../../images/cloud-studio/distro.inline.svg'
import Scalability from '../../../images/cloud-studio/scalability.inline.svg'
import Documentation from '../../../images/cloud-studio/documentation.inline.svg'
import Failover from '../../../images/cloud-studio/failover.inline.svg'
import Root from '../../../images/cloud-studio/root.inline.svg'
import IconRightArrow from '../../../images/arrow-right-alt.inline.svg'
import CloudStudioLogo from '../../../images/cloud-studio/cloud-studio-logo.inline.svg'
import VultrLogo from '../../../images/cloud-studio/vultr-logo.inline.svg'
import DigitalOceanLogo from '../../../images/cloud-studio/digital-ocean-logo.inline.svg'

function CloudStudioPage() {
  const images = useStaticQuery(graphql`
    {
      cloudMetalCard: file(
        relativePath: { eq: "cloud-studio/cloud-metal-card-bg.png" }
      ) {
        name
        childImageSharp {
          gatsbyImageData(
            quality: 100
            width: 270
            layout: CONSTRAINED
            transformOptions: { fit: CONTAIN }
          )
        }
      }
      cloudVpsCard: file(
        relativePath: { eq: "cloud-studio/cloud-vps-card-bg.png" }
      ) {
        name
        childImageSharp {
          gatsbyImageData(
            quality: 100
            width: 270
            layout: CONSTRAINED
            transformOptions: { fit: CONTAIN }
          )
        }
      }
      infrastructureCard: file(
        relativePath: { eq: "cloud-studio/infrastructure-card-bg.png" }
      ) {
        name
        childImageSharp {
          gatsbyImageData(
            quality: 100
            width: 400
            layout: CONSTRAINED
            transformOptions: { fit: CONTAIN }
          )
        }
      }
      totalControl: file(
        relativePath: { eq: "cloud-studio/total-control.png" }
      ) {
        name
        childImageSharp {
          gatsbyImageData(
            quality: 100
            layout: CONSTRAINED
            transformOptions: { fit: CONTAIN }
          )
        }
      }
      operations: file(relativePath: { eq: "cloud-studio/operations.png" }) {
        name
        childImageSharp {
          gatsbyImageData(
            quality: 100
            layout: CONSTRAINED
            transformOptions: { fit: CONTAIN }
          )
        }
      }
    }
  `)

  return (
    <Layout
      mainClass={'bg-[#0D0E11] font-roobert'}
      headerVariant={'dark'}
      footerVariant={'dark'}
    >
      <Seo
        post={{
          seo: {
            title: 'Cloud Hosting Services and Solutions | Liquid Web',
            metaDesc:
              'Cloud hosting your way—that’s the Cloud Studio advantage. Self-managed and managed plans are available, and prices start at $5 a month. Explore now.',
          },
        }}
      />
      <Hero
        label="Cloud Hosting"
        title="Cloud Studio is perfect for your"
        rotatingTitles={[
          'next big idea',
          'upcoming launch',
          'high-performance app',
          'data-heavy workload',
          'next livestream',
          'huge database',
          'testing environment',
        ]}
        subtitle="Cloud hosting engineered for high performance"
      />

      <div className="container mb-32 grid gap-10 lg:grid-cols-2">
        <Card
          variant="dark"
          label="Dedicated server"
          title="Cloud Metal"
          subtitle={
            <>
              Cloud flexibility meets bare metal power, starting at{' '}
              <span className="text-lw-white">$115 a month</span>
            </>
          }
          link={{
            url: '/products/cloud-hosting/bare-metal',
            text: 'Learn more',
          }}
          backgroundImage={images?.cloudMetalCard}
        />
        <Card
          variant="light"
          label="Virtual Server"
          title="Cloud VPS"
          subtitle={
            <>
              The perfect blend of scalability and affordability, starting at{' '}
              <span className="text-lw-black">$5 a month</span>
            </>
          }
          link={{ url: '/products/cloud-hosting/vps', text: 'Learn more' }}
          backgroundImage={images?.cloudVpsCard}
        />
      </div>

      <div className="container mb-32">
        <h2
          className={classnames(
            'text-base',
            'text-white',
            'font-normal',
            'uppercase',
            'mt-0',
            'mb-6',
            'tracking-[0.8px]'
          )}
        >
          Total Cloud Hosting Control
        </h2>
        <div className="grid gap-12 lg:grid-cols-3">
          <CardIcon
            icon={<HollowCircle />}
            content="Set up, configure, and maintain your hosting any way you like."
          />
          <CardIcon
            icon={<Intersection />}
            content="Nothing is bundled into your base plan—add only the features and services you want."
          />
          <CardIcon
            icon={<TimeGraph />}
            content="Your new instance goes from setup to production in mere minutes. "
          />
        </div>
      </div>

      <div className="container mb-32">
        <SectionTitle title="A commitment to excellence" />
        <div className="grid gap-y-10 lg:gap-x-10 lg:grid-cols-12">
          <Card
            className="col-span-12 lg:col-span-7"
            variant="dark"
            label="Reliable"
            icon={<Grow />}
            title="Expect quality"
            description="You have high standards, and so do we. That’s why Cloud Studio is built on enterprise-grade Dell and Cisco infrastructure, Intel Xeon and AMD EPYC processors, redundant 10/25G networking, and RAID-protected SSD and NVMe storage."
          />
          <Card
            className="col-span-12 lg:col-span-5"
            variant="dark"
            label="Industry veteran"
            title="Tap into 25+ years of experience"
            description="Every server in our data centers is backed by more than two decades of hosting experience."
          />
          <CardFullWidthImage
            className="col-span-12"
            variant="dark"
            label="Custom API"
            title="Enjoy full control and development convenience"
            description="A robust API, Terraform provider, and WHMCS plugin allow easy integration and automation across our platform."
            link={{
              url: '/products/cloud-hosting/cloud-api',
              text: 'Learn more',
            }}
            image={images?.totalControl}
          />
        </div>
      </div>

      <div className="container mb-32">
        <SectionTitle
          title="Superior cloud hosting on your terms"
          className="max-w-full"
        />
        <div className="grid gap-10 lg:grid-cols-2">
          <Card
            variant="light"
            label="Choice"
            title="Management flexibility"
            description="Different levels of management and optional control panel licenses mean you aren't locked into services and features that don't make sense for you."
            backgroundImage={images?.infrastructureCard}
          />
          <CardTopImage
            variant="dark"
            title="Concentrated power"
            description="Get as many or as few resources as you want, your choice. Plus, you can hot-resize from Cloud VPS to Cloud Metal, and vice versa, any time without the hassle of migrating."
            image={images?.operations}
          />
        </div>
      </div>

      <div className="container mb-32">
        <SectionTitle title="How we compare" />
        <Tabs>
          <TabContent title="Cloud VPS">
            <Table>
              <thead>
                <tr>
                  <th></th>
                  <th>
                    <CloudStudioLogo className="inline" />
                  </th>
                  <th>
                    <VultrLogo className="inline" />
                  </th>
                  <th>
                    <DigitalOceanLogo className="inline" />
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr className="divider">
                  <td colSpan={5}>Cloud VPS</td>
                </tr>
                <tr>
                  <td className="uppercase font-bold tracking-widest">
                    Monthly price
                  </td>
                  <td className="font-normal" align="center">
                    <Chip label="$15" />
                  </td>
                  <td align="center">
                    <Chip label="$15" />
                  </td>
                  <td align="center">
                    <Chip label="$18" />
                  </td>
                  <td>
                    <Button
                      className={classnames('!text-base')}
                      text="Build Now"
                      icon={<IconRightArrow />}
                      url="/configuration/cloud-studio/"
                      variant="plain"
                    />
                  </td>
                </tr>
                <tr>
                  <td className="uppercase font-bold tracking-widest">Cores</td>
                  <td className="font-bold" align="center">
                    2
                  </td>
                  <td align="center">2</td>
                  <td align="center">2</td>
                  <td>
                    <Button
                      className={classnames('!text-base')}
                      text="Build Now"
                      icon={<IconRightArrow />}
                      url="/configuration/cloud-studio/"
                      variant="plain"
                    />
                  </td>
                </tr>
                <tr>
                  <td className="uppercase font-bold tracking-widest">RAM</td>
                  <td className="font-bold" align="center">
                    4
                  </td>
                  <td align="center">4</td>
                  <td align="center">2</td>
                  <td>
                    <Button
                      className={classnames('!text-base')}
                      text="Build Now"
                      icon={<IconRightArrow />}
                      url="/configuration/cloud-studio/"
                      variant="plain"
                    />
                  </td>
                </tr>
                <tr>
                  <td className="uppercase font-bold tracking-widest">Disk</td>
                  <td className="font-bold" align="center">
                    80
                  </td>
                  <td align="center">65</td>
                  <td align="center">60</td>
                  <td>
                    <Button
                      className={classnames('!text-base')}
                      text="Build Now"
                      icon={<IconRightArrow />}
                      url="/configuration/cloud-studio/"
                      variant="plain"
                    />
                  </td>
                </tr>
                <tr>
                  <td className="uppercase font-bold tracking-widest">
                    Bandwidth
                  </td>
                  <td className="font-bold" align="center">
                    2 TB
                  </td>
                  <td align="center">3 TB</td>
                  <td align="center">3 TB</td>
                  <td>
                    <Button
                      className={classnames('!text-base')}
                      text="Build Now"
                      icon={<IconRightArrow />}
                      url="/configuration/cloud-studio/"
                      variant="plain"
                    />
                  </td>
                </tr>
              </tbody>
            </Table>
          </TabContent>
          <TabContent title="Cloud Metal">
            <Table>
              <thead>
                <tr>
                  <th></th>
                  <th>
                    <CloudStudioLogo className="inline" />
                  </th>
                  <th>
                    <VultrLogo className="inline" />
                  </th>
                  <th>
                    <DigitalOceanLogo className="inline" />
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr className="divider">
                  <td colSpan={5}>Cloud Metal</td>
                </tr>
                <tr>
                  <td className="uppercase font-bold tracking-widest">
                    Monthly price
                  </td>
                  <td className="font-normal" align="center">
                    <Chip label="$115" />
                  </td>
                  <td align="center">
                    <Chip label="$120" />
                  </td>
                  <td align="center">
                    <Chip label="$126" />
                  </td>
                  <td>
                    <Button
                      className={classnames('!text-base')}
                      text="Build Now"
                      icon={<IconRightArrow />}
                      url="/configuration/cloud-studio/"
                      variant="plain"
                    />
                  </td>
                </tr>
                <tr>
                  <td className="uppercase font-bold tracking-widest">Cores</td>
                  <td className="font-bold" align="center">
                    4
                  </td>
                  <td align="center">4</td>
                  <td align="center">4</td>
                  <td>
                    <Button
                      className={classnames('!text-base')}
                      text="Build Now"
                      icon={<IconRightArrow />}
                      url="/configuration/cloud-studio/"
                      variant="plain"
                    />
                  </td>
                </tr>
                <tr>
                  <td className="uppercase font-bold tracking-widest">RAM</td>
                  <td className="font-bold" align="center">
                    16
                  </td>
                  <td align="center">32</td>
                  <td align="center">16</td>
                  <td>
                    <Button
                      className={classnames('!text-base')}
                      text="Build Now"
                      icon={<IconRightArrow />}
                      url="/configuration/cloud-studio/"
                      variant="plain"
                    />
                  </td>
                </tr>
                <tr>
                  <td className="uppercase font-bold tracking-widest">Disk</td>
                  <td className="font-bold" align="center">
                    480
                  </td>
                  <td align="center">240</td>
                  <td align="center">50</td>
                  <td>
                    <Button
                      className={classnames('!text-base')}
                      text="Build Now"
                      icon={<IconRightArrow />}
                      url="/configuration/cloud-studio/"
                      variant="plain"
                    />
                  </td>
                </tr>
                <tr>
                  <td className="uppercase font-bold tracking-widest">
                    Bandwidth
                  </td>
                  <td className="font-bold" align="center">
                    10 TB
                  </td>
                  <td align="center">5 TB</td>
                  <td align="center">5 TB</td>
                  <td>
                    <Button
                      className={classnames('!text-base')}
                      text="Build Now"
                      icon={<IconRightArrow />}
                      url="/configuration/cloud-studio/"
                      variant="plain"
                    />
                  </td>
                </tr>
              </tbody>
            </Table>
          </TabContent>
        </Tabs>
      </div>

      <div className="container mb-32">
        <SectionTitle title="Cloud hosting FAQs" />
        <Accordion
          className="border-t border-[#3A3A3A]"
          title="What is cloud hosting?"
        >
          <p>
            Cloud hosting is a type of virtualized computing that allows you to
            host websites, applications, and data requirements without a
            physical server. Instead of manual server provisioning, cloud
            hosting enables you to easily scale your workloads across multiple
            cloud servers. The core defining features of cloud hosting are
            convenience, scalability, affordability, and reliability.
          </p>
        </Accordion>
        <Accordion title="What is Cloud Studio?">
          <p>
            Cloud Studio is Liquid Web’s suite of self-managed cloud hosting
            solutions, available on-demand starting at $5 month. Currently, it
            comprises two new products:{' '}
            <Link to="/products/cloud-hosting/bare-metal/">Cloud Metal</Link>{' '}
            and <Link to="/products/cloud-hosting/vps/">Cloud VPS</Link>. Each
            server is instantly deployable and customizable. Cloud Studio
            products are software-agnostic; they can be dropped into any new or
            existing application or software deployment.
          </p>
        </Accordion>
        <Accordion title="What can I expect from Liquid Web Cloud Studio?">
          <p>
            Expect complete control and flexibility. Unlike other cloud
            providers, Liquid Web gives you the ability to opt into the features
            you need and want, like management and control panel licenses. This
            cuts unnecessary costs and gives you hosting on your terms. Cloud
            Studio products also come with automatic infrastructure upgrades and
            maintenance, so you can focus on growing your business. And because
            you have access to the most qualified cloud experts in the industry,
            you’re only a chat, phone call, or support ticket away from
            optimizing your solution.
          </p>
        </Accordion>
        <Accordion title="How do I get started?">
          <p>
            You can get started with Cloud Studio by selecting your product.
            After you do that, you’ll see a configuration screen where you can
            customize your resources, features, and price point as you see fit.
          </p>
        </Accordion>
      </div>

      <div className="container">
        <SectionTitle
          className="max-w-[930px]"
          title="Premium cloud hosting for hungry entrepreneurs and thriving enterprises"
        />
      </div>
      <IconList className="mb-32">
        <IconListItem
          title="Solid platform."
          description="Confidently deploy and back up workloads with enterprise-grade nodes and redundant networking and storage."
          icon={<Hardware />}
        />
        <IconListItem
          title="Linux and Windows distros."
          description="We support AlmaLinux, Rocky Linux, CentOS, Ubuntu, and Windows 2019 and 2022."
          icon={<Distro />}
        />
        <IconListItem
          title="Instant scalability."
          description="In one click, increase or decrease your resources as needed to maintain optimal performance."
          icon={<Scalability />}
        />
        <IconListItem
          title="Excellent API documentation."
          description="Error code definitions, human-readable descriptions, and more enhance developer experience."
          icon={<Documentation />}
        />
        <IconListItem
          title="Backups and snapshots."
          description="Daily backups and snapshot capabilities are available."
          icon={<Failover />}
        />
        <IconListItem
          title="Self-management."
          description="Root access and our portal’s management tools give you full server control."
          icon={<Root />}
        />
      </IconList>

      <div className="container mb-32">
        <SectionTitle
          className="max-w-[710px]"
          title="Our cloud line has a product for every project"
        />
        <div className="grid gap-10 lg:grid-cols-3">
          <Card
            variant="dark"
            title="Dedicated Server Hosting"
            description="Get a server entirely devoted to your site or application"
            link={{ url: '/products/cloud-dedicated/', text: 'Explore now' }}
          />
          <Card
            variant="dark"
            title="VMware Private Cloud"
            description="Get the benefits of cloud hosting minus the complexity"
            link={{ url: '/products/private-cloud/', text: 'Explore now' }}
          />
          <Card
            variant="dark"
            title="Fully Managed VPS Hosting"
            description="Get an added boost of processing power or memory"
            link={{ url: '/products/vps/', text: 'Explore now' }}
          />
        </div>
      </div>
    </Layout>
  )
}

export default CloudStudioPage

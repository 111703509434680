import React from 'react'
import classnames from 'classnames'

function Button({ className, children }) {
  return (
    <div className={classnames('w-full', 'overflow-x-auto')}>
      <table
        className={classnames(
          className,
          'text-lw-white',
          'font-light',
          'w-full',
          'min-w-[600px]',
          '[&>thead>tr>th:not(:last-child)]:border-r-[0.5px]',
          '[&>thead>tr>th]:border-[#292929]',
          '[&>thead>tr>th]:pb-6',
          '[&>thead>tr>th]:px-6',
          '[&>thead>tr>th]:text-center',
          '[&>tbody>tr>td]:p-6',
          '[&>tbody>tr>td]:text-[15px]',
          '[&>tbody>tr>td:not(:last-child)]:border-r-[0.5px]',
          '[&>tbody>tr>td:first-child]:rounded-tl-lg',
          '[&>tbody>tr>td:first-child]:rounded-bl-lg',
          '[&>tbody>tr>td:last-child]:rounded-tr-lg',
          '[&>tbody>tr>td:last-child]:rounded-br-lg',
          '[&>tbody>tr>td]:border-[#292929]',
          '[&>tbody>tr>td]:border-[#292929]',
          '[&>tbody>tr:nth-child(even)>td]:bg-[#15151dA6]',
          '[&>tbody>tr[class="divider"]>td]:py-2',
          '[&>tbody>tr[class="divider"]>td]:border-y-[0.5px]',
          '[&>tbody>tr[class="divider"]>td]:border-[#292929]',
          '[&>tbody>tr[class="divider"]>td]:rounded-none',
          '[&>tbody>tr[class="divider"]>td]:bg-lw-blue-dark',
          '[&>tbody>tr[class="divider"]>td:not(:last-child)]:border-r-0'
        )}
      >
        {children}
      </table>
    </div>
  )
}

export default Button

import React from 'react'
import classnames from 'classnames'

function CardIcon({ className, content, icon }) {
  return (
    <div
      className={classnames(
        className,
        'px-6',
        'py-4',
        'rounded-lg',
        'flex',
        'items-center',
        'gap-6',
        'border',
        'border-solid',
        'border-[#292929]'
      )}
    >
      {icon && (
        <div
          className={classnames(
            'flex',
            'shrink-0',
            'basis-[90px]',
            'h-[90px]',
            'items-center',
            'justify-center'
          )}
        >
          {icon}
        </div>
      )}

      {content && (
        <div
          className={classnames(
            'text-lw-white',
            'text-[22px]',
            'leading-[120%]',
            'tracking-[-0.48px]',
            'lg:text-base'
          )}
        >
          {content}
        </div>
      )}
    </div>
  )
}

export default CardIcon

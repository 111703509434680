import React from 'react'
import classnames from 'classnames'

function IconListItem({ className, title, description, icon }) {
  return (
    <div
      className={classnames(
        className,
        'px-12',
        'py-16',
        'border-b',
        'border-dashed',
        'border-[#FFFFFF33]',
        'flex',
        'odd:justify-end',
        'lg:odd:border-r'
      )}
    >
      <div
        className={classnames(
          'max-w-[600px]',
          'flex',
          'flex-col',
          'items-center',
          'gap-16',
          'lg:flex-row'
        )}
      >
        <div
          className={classnames(
            'font-light',
            'text-[22px]',
            'leading-[120%]',
            'tracking-[-0.66px]'
          )}
        >
          <span className={classnames('text-lw-white')}>{title}</span>
          <span className={classnames('text-[#959595]', 'ml-1')}>
            {description}
          </span>
        </div>
        <div
          className={classnames(
            'w-[120px]',
            'h-[120px]',
            'flex',
            'shrink-0',
            'basis-[120px]',
            'items-center',
            'justify-center'
          )}
        >
          {icon}
        </div>
      </div>
    </div>
  )
}

export default IconListItem

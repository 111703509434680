import React from 'react'
import classnames from 'classnames'
import Label from './label'
import Button from './button'
import { GatsbyImage } from 'gatsby-plugin-image'
import IconRightArrow from '../../images/arrow-right-alt.inline.svg'

function CardFullWidthImage({
  className,
  variant,
  label,
  title,
  subtitle,
  description,
  link,
  image,
}) {
  return (
    <div
      className={classnames(
        className,
        variant === 'light' ? 'bg-lw-white' : 'bg-lw-blue-dark',
        variant === 'light' ? 'text-lw-black' : 'text-lw-white',
        'rounded-2xl',
        'flex',
        'flex-col',
        'lg:flex-row'
      )}
    >
      <div
        className={classnames(
          'flex-none',
          'flex',
          'flex-col',
          'items-start',
          'p-8',
          'pb-12',
          'lg:w-[50%]'
        )}
      >
        {label && (
          <Label
            className="mb-6 z-10"
            text={label}
            variant={variant === 'light' ? 'dark' : 'light'}
          />
        )}

        {title && (
          <div
            className={classnames(
              'font-light',
              'text-[32px]',
              'tracking-[-1.2px]',
              'leading-[120%]',
              'mt-auto',
              'lg:text-[40px]'
            )}
          >
            {title}
          </div>
        )}

        {subtitle && (
          <div
            className={classnames(
              'text-[#959595]',
              'font-light',
              'text-[32px]',
              'tracking-[-1.2px]',
              'leading-[120%]',
              'lg:text-[40px]'
            )}
          >
            {subtitle}
          </div>
        )}

        {description && (
          <div
            className={classnames(
              'mt-3',
              'text-[#959595]',
              'leading-[160%]',
              'tracking-[-0.16px]'
            )}
          >
            {description}
          </div>
        )}

        {link && (
          <div className="mt-7">
            <Button
              className={classnames(
                'mt-7',
                variant === 'light' && ['!text-lw-black'],
                variant === 'dark' && ['!text-lw-white']
              )}
              text={link?.text}
              icon={<IconRightArrow />}
              url={link?.url}
              variant="plain"
            />
          </div>
        )}
      </div>

      {image && (
        <div className="flex-none lg:w-[50%]">
          <GatsbyImage
            className={classnames('w-full')}
            image={image?.childImageSharp?.gatsbyImageData}
            alt={title}
          />
        </div>
      )}
    </div>
  )
}

export default CardFullWidthImage

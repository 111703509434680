import React from "react";
import classnames from "classnames";

function IconList({className, children}) {
  return (
    <div className={classnames(
      className,
      'border-t',
      'border-dashed',
      'border-[#FFFFFF33]',
      'grid',
      'grid-cols-1',
      'lg:grid-cols-2',
    )}>
      {children}
    </div>
  );
}

export default IconList;

import React from 'react'
import classnames from 'classnames'
import { useState } from 'react'

function TabTitle({ title, active, onClick }) {
  return (
    <li>
      <button
        className={classnames(
          { active },
          'p-6',
          'text-lw-white',
          'rounded-full',
          'border',
          'hover:bg-[#21212C]',
          'hover:border-[#757575]',
          active && ['border-[#757575]', 'bg-[#21212C]'],
          !active && ['border-[#7575751F]', 'bg-lw-blue-dark']
        )}
        onClick={onClick}
      >
        {title}
      </button>
    </li>
  )
}

export function TabContent({ children, active }) {
  return <div className={classnames({ active })}>{children}</div>
}

export function Tabs({ children }) {
  const [activeTab, setActiveTab] = useState(0)

  return (
    <>
      <ul className={classnames('flex', 'gap-2', 'mb-12')}>
        {children.map((child, index) => (
          <TabTitle
            key={index}
            title={child.props.title}
            active={index === activeTab}
            onClick={() => setActiveTab(index)}
          />
        ))}
      </ul>
      {children.map((child, index) => {
        if (index === activeTab) {
          return <TabContent key={index}>{child.props.children}</TabContent>
        }
      })}
    </>
  )
}

import React from 'react'
import classnames from 'classnames'
import { useState } from 'react'
import Add from '../../images/cloud-studio/add.inline.svg'
import Remove from '../../images/cloud-studio/remove.inline.svg'

function Accordion({ className, title, children }) {
  const [open, setOpen] = useState(false)
  return (
    <div
      className={classnames(className, 'border-b', 'border-[#3A3A3A]', 'py-8')}
    >
      <div
        className={classnames(
          'text-lw-white',
          'text-[32px]',
          'font-light',
          'tracking-[-0.96px]',
          'leading-[120%]',
          'cursor-pointer',
          'flex',
          'justify-between',
          'items-center',
          'gap-4',
          'group'
        )}
        onClick={() => setOpen(!open)}
      >
        {title}
        <button
          className={classnames(
            'w-12',
            'h-12',
            'min-w-[48px]',
            'rounded-full',
            'bg-[#262630]',
            'relative',
            'border',
            'border-transparent',
            'transition-all',
            'group-hover:border-[#5C5C63]'
          )}
        >
          <Add
            className={classnames(
              'absolute',
              'top-1/2',
              'left-1/2',
              '-translate-x-1/2',
              '-translate-y-1/2',
              'block',
              'transform',
              open ? 'rotate-45' : 'rotate-0',
              open ? 'opacity-0' : 'opacity-100',
              'transition-all',
              'duration-500'
            )}
          />

          <Remove
            className={classnames(
              'absolute',
              'top-1/2',
              'left-1/2',
              '-translate-x-1/2',
              '-translate-y-1/2',
              'block',
              'transform',
              open ? 'rotate-0' : 'rotate-45',
              open ? 'opacity-100' : 'opacity-0',
              'transition-all',
              'duration-500'
            )}
          />
        </button>
      </div>
      <div
        className={classnames(
          'font-[17px]',
          'text-[#959595]',
          'max-w-screen-lg',
          'tracking-[-0.17px]',
          'leading-[160%]',
          'overflow-hidden',
          open ? 'max-h-[1000px]' : 'max-h-0',
          'before:content-[""]',
          'before:block',
          'before:pt-7',
          'transition-all',
          'duration-500',
          '[&>p>a]:text-lw-text-light',
          '[&>p>a]:underline'
        )}
      >
        {children}
      </div>
    </div>
  )
}

export default Accordion

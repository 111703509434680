import React from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from '@reach/router';
import WpSeo from 'gatsby-plugin-wpgraphql-seo';
import useSeo from '../hooks/useSeo';
import { useAppConfig } from '../config';

import { swapCmsUrlInObj } from '../utils/swapCmsUrlWithCdn';

type SeoPost = any;

export interface SeoProps {
	post: SeoPost;
}

const SITE_SEP = '|';

export default function Seo(props: SeoProps): JSX.Element {
	const appConfig = useAppConfig();

	const { version, buildDate } = appConfig.appVersion;

	const { post } = props;

	const { schema } = useSeo();
	const { href } = useLocation();

	/*
	 * TODO: This should be recombined with components/structured-data
	 * However due to a bug in gatsby-plugin-wpgraphql-seo that will have to wait.
	 * Until then, we clean the seo.schema.raw from the post object to avoid issue.
	 * See: https://liquidweb.atlassian.net/browse/LW4-19
	 */
	const cleanPost = {
		...post,
		seo: {
			...post?.seo,
			schema: {
				...post?.seo?.schema,
				raw: null,
			},
		},
	};

	if (!cleanPost.seo.title) {
		cleanPost.seo.title = [cleanPost.title, schema?.siteName]
			.filter(Boolean)
			.join(` ${SITE_SEP} `);
	}

	swapCmsUrlInObj(cleanPost, 'seo.opengraphImage.sourceUrl');
	swapCmsUrlInObj(cleanPost, 'seo.opengraphImage.srcSet');
	swapCmsUrlInObj(cleanPost, 'seo.twitterImage.sourceUrl');
	swapCmsUrlInObj(cleanPost, 'seo.twitterImage.srcSet');

	return (
		<>
			<WpSeo
				post={cleanPost}
				/* postSchema={postSchema} */
			/>
			<Helmet>
				<link rel="canonical" href={href} />
				<meta name="appVersion" content={`${version}.${buildDate}`} />
			</Helmet>
		</>
	);
}
